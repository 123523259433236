import axios from 'axios';
import { getTokenFromSessionStorage } from '../utils';

const authorizedRequest = axios.create({
    baseURL: window.env.REACT_APP_API_URL
});

// Axios request interceptor that sets the Authorization token to the request header
authorizedRequest.interceptors.request.use(
    (config) => {
        const accessToken = getTokenFromSessionStorage();
        if (accessToken) {
            if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default authorizedRequest;
