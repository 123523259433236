import { IChatMessage } from '../types';
import authorizedRequest from './axios.config';

const BASE_URL = '/api/v1/rating';

// Function for sending a message rating to the API
export const rateMessage = async (
    messageId: string,
    question: string,
    answer: string,
    rating: boolean
): Promise<IChatMessage> => {
    const response = await authorizedRequest.post(BASE_URL, {
        message_id: messageId,
        question,
        answer,
        rating
    });
    return response.data;
};
