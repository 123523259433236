import { Actions, Status } from '../consts';
import { IHeadCell, ITableRow, IWebpage } from '../types';
import { resolveCreatedAtValue, resolveStatusValue } from './table.utils';

// helper function for defining heading cells for Web pages table
export const prepareWebpagesTableHeadCells = (): IHeadCell[] => [
    {
        id: 'id',
        label: 'ID',
        sortDisabled: true
    },
    {
        id: 'name',
        label: 'Name'
    },
    {
        id: 'url',
        label: 'URL',
        sortDisabled: true
    },
    {
        id: 'created_at',
        label: 'Created At'
    },
    {
        id: 'status',
        label: 'Status'
    },
    {
        id: 'actions',
        label: 'Actions',
        sortDisabled: true
    }
];

// helper function for defining table rows for Web pages table
export const prepareWebpagesTableRows = (webpages: IWebpage[]): ITableRow[] =>
    webpages.map((webpage) => {
        const { id, name, url, created_at, status } = webpage;
        return {
            id: id,
            data: [id, name, url, resolveCreatedAtValue(created_at), resolveStatusValue(status)]
        };
    });

// helper function for defining table actions for Web pages table
export const prepareWebpagesTableActions = (webpages: IWebpage[]): Actions[][] =>
    webpages.map((webpage) => {
        if (webpage.status === Status.Indexed) {
            return [Actions.EXCLUDE_WEBPAGE];
        }

        return [Actions.INDEX_WEBPAGE];
    });
