import { AppBar, Toolbar, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';
import UserMenu from './UserMenu';
import { isUserAuthenticated } from '../../utils';

interface Props {
    renderNavigationMenu?: () => ReactNode;
}

// Component that renders the top navigation in the application
export function Navbar({ renderNavigationMenu }: Props) {
    const { user } = useContext(AuthContext);
    const isAuthenticated = isUserAuthenticated(user);

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                {/* rendering of navigation menu if the sidebar is used */}
                {renderNavigationMenu && renderNavigationMenu()}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    FV ChatBot
                </Typography>

                {/* if user is authenticated render the user menu */}
                {isAuthenticated && <UserMenu />}
            </Toolbar>
        </AppBar>
    );
}
