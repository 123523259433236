import { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import IndexIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';

import { indexWebpage } from '../../../services';
import { getResponseErrorMessage } from '../../../utils';
import { ToastAlertContext } from '../../../context/ToastAlertContext';

interface Props {
    id: string;
}

// Component that handles the indexing of webpages in the table
export function IndexWebpageAction({ id }: Props) {
    const [isIndexDisabled, setIsIndexDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showAlert } = useContext(ToastAlertContext);

    const onIndexClick = async () => {
        try {
            setIsLoading(true);
            await indexWebpage(id);
            showAlert('Web page indexed.', 'success');
            setIsIndexDisabled(true);
        } catch (e) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
            setIsIndexDisabled(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Tooltip title="Index Webpage">
                <IconButton
                    disabled={isIndexDisabled}
                    color="info"
                    onClick={onIndexClick}
                    aria-label="index">
                    <IndexIcon />
                </IconButton>
            </Tooltip>
            {isLoading && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    );
}
