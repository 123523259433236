import { AlertColor } from '@mui/material';
import React, { createContext, useState, PropsWithChildren, useCallback } from 'react';
import { ToastAlert } from '../shared-components';

interface IToastAlertContext {
    showAlert: (message: string, severity: AlertColor) => void;
}

// Context that is used for showing alert messages to the user
export const ToastAlertContext = createContext<IToastAlertContext>({
    showAlert: () => undefined
});

// Context provider that exposes the showAlert function for rendering alert messages
export const ToastAlertProvider = ({ children }: PropsWithChildren) => {
    const [message, setMessage] = useState<string>();
    const [severity, setSeverity] = useState<AlertColor>();

    const closeAlert = useCallback(() => {
        setMessage(undefined);
        setSeverity(undefined);
    }, []);

    const showAlert = useCallback((message: string, severity: AlertColor) => {
        setMessage(message);
        setSeverity(severity);
    }, []);

    return (
        <ToastAlertContext.Provider
            value={{
                showAlert
            }}>
            {message && severity && (
                <ToastAlert message={message} severity={severity} onClose={closeAlert} />
            )}
            {children}
        </ToastAlertContext.Provider>
    );
};
