import { IPagination, IQueryParams, IUser } from '../types';
import { prepareQueryParams } from '../utils';
import authorizedRequest from './axios.config';

const BASE_URL = '/api/v1/users';

// Function for fetching Users from the API
export const getUsers = async (queryParams: IQueryParams): Promise<IPagination<IUser>> => {
    const urlSearchParams = prepareQueryParams(queryParams);
    const response = await authorizedRequest.get(`${BASE_URL}/paged?${urlSearchParams}`);
    return response.data;
};
