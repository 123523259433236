import { Actions, Status } from '../consts';
import { IHeadCell, IPdfFile, ITableRow } from '../types';
import {
    resolveCreatedAtValue,
    resolveExtensionTypeValue,
    resolveStatusValue
} from './table.utils';

// helper function for defining heading cells for PDF files table
export const preparePDFTableHeadCells = (): IHeadCell[] => [
    {
        id: 'id',
        label: 'ID',
        sortDisabled: true
    },
    {
        id: 'name',
        label: 'Name'
    },
    {
        id: 'created_at',
        label: 'Created At'
    },
    {
        id: 'status',
        label: 'Status'
    },
    {
        id: 'extension_type',
        label: 'Extension',
        sortDisabled: true
    },
    {
        id: 'actions',
        label: 'Actions',
        sortDisabled: true
    }
];

// helper function for defining table rows for PDF files table
export const preparePDFTableRows = (pdfFiles: IPdfFile[]): ITableRow[] =>
    pdfFiles.map((file) => {
        const { id, name, created_at, status, extension_type } = file;
        return {
            id: id,
            data: [
                id,
                name,
                resolveCreatedAtValue(created_at),
                resolveStatusValue(status),
                resolveExtensionTypeValue(extension_type)
            ]
        };
    });

// helper function for defining table actions for PDF files table
export const preparePDFTableActions = (pdfFiles: IPdfFile[]): Actions[][] =>
    pdfFiles.map((file) => {
        if (file.status === Status.Indexed) {
            return [Actions.DOWNLOAD, Actions.EXCLUDE_PDF];
        }

        return [Actions.DOWNLOAD, Actions.INDEX_PDF];
    });
