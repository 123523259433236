import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import { AxiosError } from 'axios';

import { LayoutWithSidebar } from '../../layouts';
import { IQueryParams, IWebpage } from '../../types';
import {
    getResponseErrorMessage,
    prepareWebpagesTableActions,
    prepareWebpagesTableHeadCells,
    prepareWebpagesTableRows
} from '../../utils';
import { DEFAULT_QUERY_PARAMS } from '../../consts';
import { Table, EmptyScreen } from '../../shared-components';
import { PageHeader, WebpagesFilters } from './components';
import { getWebpages } from '../../services';
import { ToastAlertContext } from '../../context/ToastAlertContext';

// Webpages page that renders the page with a list of web pages that are parsed
export function Webpages() {
    const [webpages, setWebPages] = useState<IWebpage[]>([]);
    const [selectedWebpages, setSelectedWebpages] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<IQueryParams>(DEFAULT_QUERY_PARAMS);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { showAlert } = useContext(ToastAlertContext);

    // useEffect for fetching of Webpages when queryParams changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await getWebpages(queryParams);
                setWebPages(response.data);
                setTotalCount(response.count);
            } catch (e: unknown) {
                const errorMessage = getResponseErrorMessage(e as AxiosError);
                showAlert(errorMessage, 'error');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [queryParams, showAlert]);

    // onUpdateQueryParams is triggered when user changes the sort, pagination or filters
    const onUpdateQueryParams = (updatedData: Partial<IQueryParams>) => {
        setQueryParams((prevData) => ({
            ...prevData,
            ...updatedData
        }));
        setSelectedWebpages([]);
    };

    return (
        <LayoutWithSidebar>
            <Box sx={{ p: 6 }}>
                {/* Page header rendering */}
                <PageHeader webpages={webpages} setWebPages={setWebPages} />

                {/* Page filters rendering */}
                <WebpagesFilters onUpdateQueryParams={onUpdateQueryParams} />

                {/* Table/Empty screen rendering */}
                {!isLoading && webpages.length === 0 ? (
                    <EmptyScreen text="No webpages found." icon={<WebIcon />} />
                ) : (
                    <Table
                        actions={prepareWebpagesTableActions(webpages)}
                        headCells={prepareWebpagesTableHeadCells()}
                        isLoading={isLoading}
                        rows={prepareWebpagesTableRows(webpages)}
                        totalCount={totalCount}
                        queryParams={queryParams}
                        onUpdateQueryParams={onUpdateQueryParams}
                        setSelected={setSelectedWebpages}
                        selected={selectedWebpages}
                    />
                )}
            </Box>
        </LayoutWithSidebar>
    );
}
