import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import { AxiosError } from 'axios';

import { IWebpage } from '../../../types';
import { useContext, useState } from 'react';
import { createWebpage } from '../../../services';
import { getResponseErrorMessage } from '../../../utils';
import { ToastAlertContext } from '../../../context/ToastAlertContext';

interface Props {
    webpages: IWebpage[];
    setWebpages: (webpages: IWebpage[]) => void;
}

// Component that handles the creation of a webpage
export function CreateWebpageDialog({ webpages, setWebpages }: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showAlert } = useContext(ToastAlertContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setUrl('');
    };

    const onCreateClick = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            setIsLoading(true);
            const webpage = await createWebpage(name, url);
            setWebpages([webpage, ...webpages]);
            showAlert('Web page created successfully.', 'success');
            handleClose();
        } catch (e) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                onClick={handleClickOpen}
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<WebIcon />}>
                Create a Webpage
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                PaperProps={{
                    component: 'form',
                    onSubmit: onCreateClick
                }}>
                <DialogTitle>Create a web page</DialogTitle>
                <DialogContent>
                    <DialogContentText>Add web page to parse for your chatbot.</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="webpage name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{ my: 2 }}
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="webpage url"
                        label="URL"
                        type="url"
                        fullWidth
                        variant="standard"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={isLoading} type="submit">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
