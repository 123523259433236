import { Box, Typography } from '@mui/material';

import { IWebpage } from '../../../types';
import { CreateWebpageDialog } from './CreateWebpageDialog';

interface Props {
    webpages: IWebpage[];
    setWebPages: (webpages: IWebpage[]) => void;
}

// Component that renders the page header with a page title and create/delete button
export function PageHeader({ webpages, setWebPages }: Props) {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 6 }}>
            <Typography variant="h5">Web pages</Typography>
            <CreateWebpageDialog webpages={webpages} setWebpages={setWebPages} />
        </Box>
    );
}
