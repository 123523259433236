import { useContext, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import DownloadIcon from '@mui/icons-material/DownloadForOfflineRounded';

import { getResponseErrorMessage } from '../../../utils';
import { downloadPdfFile } from '../../../services';
import { ToastAlertContext } from '../../../context/ToastAlertContext';

interface Props {
    id: string;
}

// Component that handles the PDF download action in the table
export function PdfDownloadAction({ id }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showAlert } = useContext(ToastAlertContext);

    const onFileDownload = async () => {
        try {
            setIsLoading(true);
            await downloadPdfFile(id);
        } catch (e: unknown) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Tooltip title="Download">
            <IconButton disabled={isLoading} onClick={onFileDownload} aria-label="download">
                {isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
            </IconButton>
        </Tooltip>
    );
}
