import { createContext, useState, useEffect, PropsWithChildren, useContext } from 'react';

import {
    deleteTokenFromSessionStorage,
    getTokenFromSessionStorage,
    getUserFromToken,
    isUserAuthenticated
} from '../utils';
import { ILoggedUser } from '../types';
import { ToastAlertContext } from './ToastAlertContext';

interface IAuthContext {
    user: ILoggedUser | null;
    setUser: (user: ILoggedUser | null) => void;
}

// Context that is storing the user authentication state
export const AuthContext = createContext<IAuthContext>({
    user: null,
    setUser: (user: ILoggedUser | null) => user
});

// Context provider that checks if the user is logged in or not on first mount
export const AuthProvider = ({ children }: PropsWithChildren) => {
    const [user, setUser] = useState<ILoggedUser | null>(null);

    const { showAlert } = useContext(ToastAlertContext);

    useEffect(() => {
        const checkAuthStatus = () => {
            try {
                // Get the user data from session storage
                const token = getTokenFromSessionStorage();
                const user = getUserFromToken(token);

                // If the user is authenticated save it to state, if not set it to null and delete the expired token
                if (isUserAuthenticated(user)) {
                    setUser(user);
                } else {
                    setUser(null);
                    deleteTokenFromSessionStorage();
                }
            } catch {
                setUser(null);
            }
        };

        checkAuthStatus();
    }, [showAlert]);

    return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};
