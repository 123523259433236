import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import { AxiosError } from 'axios';

import { LayoutWithSidebar } from '../../layouts';
import { IQueryParams, IUser } from '../../types';
import {
    getResponseErrorMessage,
    prepareUsersTableHeadCells,
    prepareUsersTableRows
} from '../../utils';
import { DEFAULT_QUERY_PARAMS } from '../../consts';
import { Table, EmptyScreen } from '../../shared-components';
import { PageHeader, UsersFilters } from './components';
import { getUsers } from '../../services';
import { ToastAlertContext } from '../../context/ToastAlertContext';

// Webpages page that renders the page with a list of users
export function Users() {
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<IQueryParams>(DEFAULT_QUERY_PARAMS);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { showAlert } = useContext(ToastAlertContext);

    // useEffect for fetching of users when queryParams changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await getUsers(queryParams);
                setUsers(response.data);
                setTotalCount(response.count);
            } catch (e: unknown) {
                const errorMessage = getResponseErrorMessage(e as AxiosError);
                showAlert(errorMessage, 'error');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [queryParams, showAlert]);

    // onUpdateQueryParams is triggered when user changes the sort, pagination or filters
    const onUpdateQueryParams = (updatedData: Partial<IQueryParams>) => {
        setQueryParams((prevData) => ({
            ...prevData,
            ...updatedData
        }));
        setSelectedUsers([]);
    };

    return (
        <LayoutWithSidebar>
            <Box sx={{ p: 6 }}>
                {/* Page header rendering */}
                <PageHeader />

                {/* Page filters rendering */}
                <UsersFilters onUpdateQueryParams={onUpdateQueryParams} />

                {/* Table/Empty screen rendering */}
                {!isLoading && users.length === 0 ? (
                    <EmptyScreen text="No users found." icon={<WebIcon />} />
                ) : (
                    <Table
                        headCells={prepareUsersTableHeadCells()}
                        isLoading={isLoading}
                        rows={prepareUsersTableRows(users)}
                        totalCount={totalCount}
                        queryParams={queryParams}
                        onUpdateQueryParams={onUpdateQueryParams}
                        setSelected={setSelectedUsers}
                        selected={selectedUsers}
                    />
                )}
            </Box>
        </LayoutWithSidebar>
    );
}
