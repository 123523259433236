import { Status } from '../consts';
import { IClaim } from '../types';

// helper function for checking if a table row is selected or not
export const isRowSelected = (selectedIndexes: number[], index: number): boolean => {
    const isSelected = selectedIndexes.find((i) => i === index);
    return isSelected !== undefined;
};

// helper function for creating an array of row indexes
// used for select all checkbox for setting all of the row indexes to state
export const getTableRowIndexes = (rows: object[]): number[] => {
    return rows.map((row, index) => index);
};

// helper function for resolving status value in the table
export const resolveStatusValue = (status?: Status): string => {
    if (status === undefined || status === null) return '';

    // first letter uppercased
    return status.charAt(0).toUpperCase() + status.slice(1);
};

// helper function for resolving created_at value in the table
// format: MM/DD/YYYY
export const resolveCreatedAtValue = (createdAt: string): string => {
    return new Date(createdAt).toLocaleDateString('en-US');
};

// helper function for resolving extension_type value in the table
export const resolveExtensionTypeValue = (extensionType: string): string => {
    if (extensionType === 'application/pdf') return '.pdf';

    return extensionType;
};

// helper function for resolving claims value in the table
export const resolveClaimsValue = (claims: IClaim[]): string => {
    const claimValues = claims.map((claim) => claim.value);
    return claimValues.toString();
};
