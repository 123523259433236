import { Box, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

import { Navbar, Sidebar } from '../shared-components';
import { SIDEBAR_WIDTH } from '../consts';

export function LayoutWithSidebar({ children }: React.PropsWithChildren) {
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [isMobileSidebarClosing, setIsMobileSidebarClosing] = useState(false);

    // Called when closing mobile sidebar
    const onMobileSidebarClose = () => {
        setIsMobileSidebarClosing(true);
        setIsMobileSidebarOpen(false);
    };

    // Called when sidebar closing animation finishes
    const onMobileSidebarTransitionEnd = () => {
        setIsMobileSidebarClosing(false);
    };

    // Called when user clicks on Menu icon
    const onMenuButtonClick = () => {
        if (isMobileSidebarClosing) return;
        setIsMobileSidebarOpen(!isMobileSidebarOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {/* Top navigation bar */}
            <Navbar
                renderNavigationMenu={() => (
                    <IconButton
                        color="inherit"
                        aria-label="open sidebar"
                        edge="start"
                        onClick={onMenuButtonClick}
                        sx={{ mr: 2, display: { sm: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                )}
            />

            {/* Sidebar */}
            <Sidebar
                isMobileSidebarOpen={isMobileSidebarOpen}
                onMobileSidebarClose={onMobileSidebarClose}
                onMobileSidebarTransitionEnd={onMobileSidebarTransitionEnd}
            />

            {/* Main content - rendering children components */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: { sm: `calc(100% - ${SIDEBAR_WIDTH}px)` },
                    minHeight: '100vh',
                    bgcolor: 'grey.100',
                    overflowX: 'auto'
                }}>
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
}
