import { Box, Toolbar } from '@mui/material';

import { Navbar } from '../shared-components';

export function LayoutWithNavbar({ children }: React.PropsWithChildren) {
    return (
        <Box sx={{ display: 'flex' }}>
            {/* Top navigation bar */}
            <Navbar />

            {/* Main content - rendering children components */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    minHeight: '100vh',
                    bgcolor: 'grey.100',
                    overflowX: 'auto'
                }}>
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
}
