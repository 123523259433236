import { AxiosError } from 'axios';
import { IQueryParams, IServiceError } from '../types';

const DEFAULT_ERROR = 'An application error occurred. Please try again.';

// Helper function for getting an error message from the response
export const getResponseErrorMessage = (error?: AxiosError): string => {
    try {
        const responseData = error?.response?.data;
        if (responseData === undefined) return DEFAULT_ERROR;

        const errorMessage = (responseData as IServiceError).errors?.general[0];
        return errorMessage ?? DEFAULT_ERROR;
    } catch {
        return DEFAULT_ERROR;
    }
};

// Helper function for removing empty values from queryParams and parsing them with URLSearchParams
export const prepareQueryParams = (queryParams: IQueryParams): string => {
    const { skip, take, order, orderBy, name = '', status = '', email = '' } = queryParams;

    const urlSearchParams: Record<string, string> = {
        skip: skip.toString(),
        take: take.toString(),
        order_by: `${orderBy} ${order}`,
        name: name,
        status: status,
        email: email
    };

    for (const key in urlSearchParams) {
        if (urlSearchParams[key] === '') {
            delete urlSearchParams[key];
        }
    }

    return new URLSearchParams(urlSearchParams).toString();
};
