import { IPagination, IQueryParams, IWebpage } from '../types';
import { prepareQueryParams } from '../utils';
import authorizedRequest from './axios.config';

const BASE_URL = '/api/v1/web_pages';

// Function for fetching Webpages from the API
export const getWebpages = async (queryParams: IQueryParams): Promise<IPagination<IWebpage>> => {
    const urlSearchParams = prepareQueryParams(queryParams);
    const response = await authorizedRequest.get(`${BASE_URL}/paged?${urlSearchParams}`);
    return response.data;
};

// Function for creating Webpages
export const createWebpage = async (name: string, url: string): Promise<IWebpage> => {
    const response = await authorizedRequest.post(`${BASE_URL}`, { name, url });
    return response.data;
};

// Function for indexing Webpages
export const indexWebpage = async (id: string): Promise<IWebpage> => {
    const response = await authorizedRequest.post(`${BASE_URL}/${id}/index`);
    return response.data;
};

export const excludeWebPageFromIndex = async (id: string): Promise<IWebpage> => {
    const response = await authorizedRequest.post(`${BASE_URL}/${id}/exclude`);
    return response.data;
};
