import { Box, Drawer } from '@mui/material';

import { SidebarMenu } from './SidebarMenu';
import { SIDEBAR_WIDTH } from '../../consts';

interface Props {
    isMobileSidebarOpen: boolean;
    onMobileSidebarTransitionEnd: () => void;
    onMobileSidebarClose: () => void;
}

// Component that renders the sidebar of the application
export function Sidebar({
    isMobileSidebarOpen,
    onMobileSidebarTransitionEnd,
    onMobileSidebarClose
}: Props) {
    return (
        <Box component="nav" sx={{ width: { sm: SIDEBAR_WIDTH }, flexShrink: { sm: 0 } }}>
            {/* Mobile sidebar */}
            <Drawer
                variant="temporary"
                open={isMobileSidebarOpen}
                onTransitionEnd={onMobileSidebarTransitionEnd}
                onClose={onMobileSidebarClose}
                ModalProps={{
                    keepMounted: true
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH }
                }}>
                <SidebarMenu />
            </Drawer>

            {/* Desktop sidebar */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH }
                }}
                open>
                <SidebarMenu />
            </Drawer>
        </Box>
    );
}
