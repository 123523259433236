import { NavLink } from 'react-router-dom';
import {
    Toolbar,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import PDFIcon from '@mui/icons-material/PictureAsPdfRounded';
import WebsiteIcon from '@mui/icons-material/WebRounded';
import { useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';
import { hasAdminClaim } from '../../utils';

// Component that renders the sidebar menu items
export function SidebarMenu() {
    const { user } = useContext(AuthContext);
    const isAdmin = hasAdminClaim(user);

    return (
        <div>
            <Toolbar />
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton component={NavLink} to="/">
                        <ListItemIcon>
                            <ChatIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Chat'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />

            {/* If the user is admin render the PDF Documents & Web Pages links */}
            {isAdmin && (
                <List>
                    <ListItem disablePadding>
                        <ListItemButton component={NavLink} to="/admin/pdf-files">
                            <ListItemIcon>
                                <PDFIcon />
                            </ListItemIcon>
                            <ListItemText primary={'PDF Documents'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={NavLink} to="/admin/web-pages">
                            <ListItemIcon>
                                <WebsiteIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Web Pages'} />
                        </ListItemButton>
                    </ListItem>
                </List>
            )}
        </div>
    );
}
