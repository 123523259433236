import { TableHead as MuiTableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

import { IHeadCell, IQueryParams } from '../../types';
import { ASC_ORDER, DESC_ORDER } from '../../consts';

interface Props {
    allRowsSelected: boolean;
    headCells: IHeadCell[];
    queryParams: IQueryParams;
    onUpdateQueryParams: (updatedData: Partial<IQueryParams>) => void;
    onSelectAll: (checked: boolean) => void;
}

// Component that is rendering the table header and maps through the heading cells
export function TableHead({ headCells, queryParams, onUpdateQueryParams }: Props) {
    const { orderBy, order } = queryParams;

    const onSortClick = (property: string) => {
        const isAsc = orderBy === property && order === ASC_ORDER;
        onUpdateQueryParams({
            order: isAsc ? DESC_ORDER : ASC_ORDER,
            orderBy: property
        });
    };

    // Rendering of a table head cell depending on if it is sortable or not
    const renderHeadCell = (headCell: IHeadCell) => {
        const { sortDisabled, id, label } = headCell;

        if (sortDisabled) return <TableCell key={id}>{label}</TableCell>;

        return (
            <TableCell key={id} sortDirection={orderBy === id ? order : false}>
                <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : ASC_ORDER}
                    onClick={() => onSortClick(id)}>
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <MuiTableHead>
            <TableRow>
                {/* TODO - select all checkbox - uncomment when bulk actions are implemented                    
                        <TableCell padding="checkbox">
                            <Checkbox
                                name="select-all-checkbox"
                                color="primary"
                                checked={allRowsSelected}
                                onChange={(e) => onSelectAll(e.target.checked)}
                            />
                        </TableCell>
                */}

                {/* Head cells mapping */}
                {headCells.map(renderHeadCell)}
            </TableRow>
        </MuiTableHead>
    );
}
