import { Fragment } from 'react';
import { Box } from '@mui/material';

import { Actions } from '../../../consts';
import { PdfDownloadAction } from './PdfDownloadAction';
import { IndexPdfAction } from './IndexPdfAction';
import { ExcludePdfAction } from './ExcludePdfAction';
import { IndexWebpageAction } from './IndexWebpageAction';
import { ExcludeWebPageAction } from './ExcludeWebPageAction';

interface Props {
    actions: Actions[];
    id: string;
}

// Component that is rendering the table actions for each row
export function TableActions({ actions, id }: Props) {
    // Rendering of the actions depending on the passed values from props
    const renderTableAction = (action: Actions) => {
        switch (action) {
            case Actions.DOWNLOAD:
                return <PdfDownloadAction id={id} />;
            case Actions.INDEX_PDF:
                return <IndexPdfAction id={id} />;
            case Actions.INDEX_WEBPAGE:
                return <IndexWebpageAction id={id} />;
            case Actions.EXCLUDE_PDF:
                return <ExcludePdfAction id={id} />;
            case Actions.EXCLUDE_WEBPAGE:
                return <ExcludeWebPageAction id={id} />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {actions.map((action, index) => (
                <Fragment key={index}>{renderTableAction(action)}</Fragment>
            ))}
        </Box>
    );
}
