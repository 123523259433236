import { IChatMessage } from '../types';
import authorizedRequest from './axios.config';

const BASE_URL = '/api/v1/conversations';

// Function for sending a message to the API
export const sendMessage = async (message: string): Promise<IChatMessage> => {
    // TODO - this should be a real conversation id
    const randomUUID = crypto.randomUUID();

    const response = await authorizedRequest.post(`${BASE_URL}/${randomUUID}/chat`, { message });
    return response.data;
};
