import { Avatar, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
    text: string;
    icon: ReactNode;
}

// Component that is used as a visual helper for the user when there is no content on the page
export function EmptyScreen({ text, icon }: Props) {
    return (
        <Box
            sx={{
                mt: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Avatar sx={{ backgroundColor: 'primary.main', mb: 2, width: 56, height: 56 }}>
                {icon}
            </Avatar>
            <Typography variant="h5">{text}</Typography>
        </Box>
    );
}
