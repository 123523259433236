import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import StopIcon from '@mui/icons-material/Stop';

interface Props {
    isLoading: boolean;
    message: string;
}

// Component that renders the typing effect for messages. It is used when the API is sending a response.
export function MessageTyping({ isLoading, message }: Props) {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const isTypingInProgress = currentIndex < message.length;

    // Effect for handling the logic of typing effect. It uses setTimeout to store a letter and the index every 40ms to the state.
    useEffect(() => {
        if (currentIndex < message.length) {
            const timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + message[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, 20);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, message]);

    const onStopAnsweringClick = () => {
        setCurrentIndex(message.length + 1);
    };

    if (isLoading) return <LinearProgress />;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body1">{currentText}</Typography>
            {isTypingInProgress && (
                <Button
                    onClick={onStopAnsweringClick}
                    sx={{ width: '200px' }}
                    color="secondary"
                    variant="outlined"
                    startIcon={<StopIcon />}>
                    Stop answering
                </Button>
            )}
        </Box>
    );
}
