import { useContext } from 'react';
import { Box, Avatar, Paper, Typography } from '@mui/material';

import { MessageTyping } from './MessageTyping';
import { AuthContext } from '../../../context/AuthContext';
import { getUserInitalLetter } from '../../../utils';
import { MessageRating } from './MessageRating';
import { IChatMessage } from '../../../types';

interface Props {
    message: IChatMessage;
    isLoading: boolean;
    isMessageReviewed: boolean;
    isBotMessage: boolean;
    hasTypingEffect?: boolean;
    onReviewMessage: (id: string, review: boolean) => void;
}

// Component that is rendering a single message inside the chat message list
export function Message({
    message,
    isLoading,
    isMessageReviewed,
    isBotMessage,
    hasTypingEffect,
    onReviewMessage
}: Props) {
    const { user } = useContext(AuthContext);
    const showRating = isBotMessage && !isLoading && !isMessageReviewed;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                mb: 2,
                wordBreak: 'break-word'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                {/* Author avatar rendering */}
                <Avatar
                    sx={{
                        bgcolor: isBotMessage ? 'primary.main' : 'secondary.main',
                        width: 46,
                        height: 46
                    }}>
                    {isBotMessage ? 'FV' : getUserInitalLetter(user)}
                </Avatar>

                {/* Message content rendering */}
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                        ml: 1,
                        borderRadius: '20px 20px 20px 5px'
                    }}>
                    <Typography color="grey.600" variant="caption" gutterBottom>
                        {isBotMessage ? 'Franchise Ventures Chatbot' : 'You'}
                    </Typography>
                    {hasTypingEffect ? (
                        <MessageTyping isLoading={isLoading} message={message.content} />
                    ) : (
                        <Typography variant="body1">{message.content}</Typography>
                    )}
                </Paper>

                {showRating && (
                    <MessageRating messageId={message.id} onReviewMessage={onReviewMessage} />
                )}
            </Box>
        </Box>
    );
}
