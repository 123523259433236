import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

import { IChatMessage } from '../../../types';
import { Message } from './Message';
import { EmptyScreen } from '../../../shared-components';
import { BOT_NAME } from '../../../consts';

interface Props {
    reviewedMessages: string[];
    isMessageLoading: boolean;
    messages: IChatMessage[];
    onReviewMessage: (id: string, review: boolean) => void;
}

/* Component that is responsible for rendering the list of messages in the chat page
   It also renders the empty screen if no messages are present
*/
export function MessageList({
    isMessageLoading,
    messages,
    reviewedMessages,
    onReviewMessage
}: Props) {
    const messageListRef = useRef<HTMLDivElement>(null);

    // Effect for scrolling to the bottom of the page when new messages appear
    useEffect(() => {
        if (messageListRef.current === null || messages.length === 0) return;
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }, [messages]);

    const renderMessage = (message: IChatMessage, index: number) => {
        const isLastMessage = index === messages.length - 1;
        const isBotMessage = message.name === BOT_NAME;
        const isMessageReviewed = reviewedMessages.find((messageId) => messageId === message.id);

        return (
            <Message
                key={index}
                isMessageReviewed={!!isMessageReviewed}
                isBotMessage={isBotMessage}
                isLoading={isMessageLoading}
                message={message}
                hasTypingEffect={isLastMessage && isBotMessage}
                onReviewMessage={onReviewMessage}
            />
        );
    };

    return (
        <Box ref={messageListRef} sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
            {messages.length === 0 ? (
                <EmptyScreen text="How can I help you today?" icon={<ChatIcon />} />
            ) : (
                <>{messages.map(renderMessage)}</>
            )}
        </Box>
    );
}
