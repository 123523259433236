import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { PdfFiles, Webpages, Chatbot, Login, Users } from './pages';
import './styles/index.css';
import { theme } from './styles/theme';
import { AdminRoute, UserRoute } from './shared-components';
import { AuthProvider, ToastAlertProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <UserRoute>
                <Chatbot />
            </UserRoute>
        )
    },
    {
        path: '/admin/pdf-files',
        element: (
            <AdminRoute>
                <PdfFiles />
            </AdminRoute>
        )
    },
    {
        path: '/admin/web-pages',
        element: (
            <AdminRoute>
                <Webpages />
            </AdminRoute>
        )
    },
    {
        path: '/admin/users',
        element: (
            <AdminRoute>
                <Users />
            </AdminRoute>
        )
    },
    {
        path: '/login',
        element: <Login />
    }
]);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <ToastAlertProvider>
                <AuthProvider>
                    <RouterProvider router={router} />
                </AuthProvider>
            </ToastAlertProvider>
            <CssBaseline />
        </ThemeProvider>
    </React.StrictMode>
);
