import axios from 'axios';
import { IToken } from '../types/';

const BASE_URL = `${window.env.REACT_APP_API_URL}/api/v1/authentication`;

// Function for logging in the user
export const authenticateUser = async (email: string, password: string): Promise<IToken> => {
    const body = {
        username: email,
        password: password
    };

    const response = await axios.post<IToken>(`${BASE_URL}/login`, body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    return response.data;
};
