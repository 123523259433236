import { TableCell, TableRow as MuiTableRow } from '@mui/material';

import { isRowSelected } from '../../utils';
import { TableActions } from './table-actions/TableActions';
import { Actions } from '../../consts';
import { ITableRow } from '../../types';

interface Props {
    actions?: Actions[];
    row: ITableRow;
    index: number;
    selected: number[];
    onSelectRow: (index: number) => void;
}

// Component for each table row with a checkbox, cells and actions menu
export function TableRow({ actions, row, index, selected }: Props) {
    const isSelected = isRowSelected(selected, index);
    const hasActions = actions !== undefined;

    return (
        <MuiTableRow selected={isSelected} hover tabIndex={-1}>
            {/* TODO - select row checkbox - uncomment when bulk actions are implemented
                    <TableCell padding="checkbox">
                        <Checkbox
                            name={`select-row-checkbox-${index}`}
                            onChange={() => onSelectRow(index)}
                            color="primary"
                            checked={isSelected}
                        />
                    </TableCell>
            */}

            {/* Table row cells */}
            {row.data.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
            ))}

            {/* Table row actions */}
            {hasActions && (
                <TableCell>
                    <TableActions id={row.id} actions={actions} />
                </TableCell>
            )}
        </MuiTableRow>
    );
}
