import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { AxiosError } from 'axios';

import { LayoutWithSidebar } from '../../layouts';
import { IQueryParams, IPdfFile } from '../../types';
import {
    preparePDFTableRows,
    preparePDFTableHeadCells,
    getResponseErrorMessage,
    preparePDFTableActions
} from '../../utils';
import { DEFAULT_QUERY_PARAMS } from '../../consts';
import { Table, EmptyScreen } from '../../shared-components';
import { PageHeader, PdfFilters } from './components';
import { getPdfFiles } from '../../services';
import { ToastAlertContext } from '../../context/ToastAlertContext';

// PdfFiles page that renders the page with a list of documents that are uploaded
export function PdfFiles() {
    const [files, setFiles] = useState<IPdfFile[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<IQueryParams>(DEFAULT_QUERY_PARAMS);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { showAlert } = useContext(ToastAlertContext);

    // useEffect for fetching of PDF files when queryParams changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await getPdfFiles(queryParams);
                setFiles(response.data);
                setTotalCount(response.count);
            } catch (e: unknown) {
                const errorMessage = getResponseErrorMessage(e as AxiosError);
                showAlert(errorMessage, 'error');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [queryParams, showAlert]);

    // onUpdateQueryParams is triggered when user changes the sort, pagination or filters
    const onUpdateQueryParams = (updatedData: Partial<IQueryParams>) => {
        setQueryParams((prevData) => ({
            ...prevData,
            ...updatedData
        }));
        setSelectedFiles([]);
    };

    return (
        <LayoutWithSidebar>
            <Box sx={{ p: 6 }}>
                {/* Page header rendering */}
                <PageHeader files={files} setFiles={setFiles} />

                {/* Page filters rendering */}
                <PdfFilters onUpdateQueryParams={onUpdateQueryParams} />

                {/* Table/Empty screen rendering */}
                {!isLoading && files.length === 0 ? (
                    <EmptyScreen text="No PDF files found." icon={<PdfIcon />} />
                ) : (
                    <Table
                        actions={preparePDFTableActions(files)}
                        isLoading={isLoading}
                        headCells={preparePDFTableHeadCells()}
                        rows={preparePDFTableRows(files)}
                        totalCount={totalCount}
                        queryParams={queryParams}
                        onUpdateQueryParams={onUpdateQueryParams}
                        setSelected={setSelectedFiles}
                        selected={selectedFiles}
                    />
                )}
            </Box>
        </LayoutWithSidebar>
    );
}
