import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

import { Status } from '../../consts';
import { resolveStatusValue } from '../../utils';

interface Props {
    statuses: Status[];
    onStatusChange: (status: string) => void;
}

// Component for rendering the filter by status
export function StatusSelect({ statuses, onStatusChange }: Props) {
    const [status, setStatus] = useState<string>('');

    const handleChange = (e: SelectChangeEvent) => {
        setStatus(e.target.value);
        onStatusChange(e.target.value);
    };

    return (
        <FormControl
            sx={{ ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 }, minWidth: 180 }}
            size="small">
            <InputLabel id="status-label">Filter by status</InputLabel>
            <Select
                labelId="status-label"
                id="status-filter"
                value={status}
                label="Filter by status"
                onChange={handleChange}>
                <MenuItem value="">
                    <em>All</em>
                </MenuItem>
                {statuses.map((status, index) => (
                    <MenuItem key={index} value={status}>
                        {resolveStatusValue(status)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
