import { Box, Paper, Table as MuiTable, TableContainer } from '@mui/material';

import { TableHead } from './TableHead';
import { TablePagination } from './TablePagination';
import { IHeadCell, IQueryParams, ITableRow } from '../../types';
import { TableBody } from './TableBody';
import { getTableRowIndexes, isRowSelected } from '../../utils';
import { TableLoader } from './TableLoader';
import { Actions } from '../../consts';

interface Props {
    actions?: Actions[][];
    headCells: IHeadCell[];
    isLoading?: boolean;
    rows: ITableRow[];
    totalCount: number;
    queryParams: IQueryParams;
    selected: number[];
    onUpdateQueryParams: (updatedData: Partial<IQueryParams>) => void;
    setSelected: (selected: number[]) => void;
}

// Component that is rendering all of the table components
// This is the only component that should be called when a table is needed on a page
export function Table({
    actions,
    headCells,
    isLoading,
    selected,
    rows,
    totalCount,
    queryParams,
    onUpdateQueryParams,
    setSelected
}: Props) {
    // onSelectAll is triggered when the user clicks the checkbox in the table header
    const onSelectAll = (checked: boolean) => {
        if (checked) {
            const fileIndexes = getTableRowIndexes(rows);
            setSelected([...fileIndexes]);
            return;
        }

        setSelected([]);
    };

    // onSelectFile is triggered when the user clicks the checkbox in a single table row
    const onSelectRow = (index: number) => {
        const isSelected = isRowSelected(selected, index);
        if (isSelected) {
            setSelected(selected.filter((fileIndex) => fileIndex !== index));
            return;
        }

        setSelected([...selected, index]);
    };

    if (isLoading) return <TableLoader />;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <MuiTable sx={{ minWidth: 750 }} aria-labelledby="Table" size={'medium'}>
                        <TableHead
                            allRowsSelected={selected.length === rows.length}
                            headCells={headCells}
                            queryParams={queryParams}
                            onUpdateQueryParams={onUpdateQueryParams}
                            onSelectAll={onSelectAll}
                        />
                        <TableBody
                            actions={actions}
                            rows={rows}
                            selected={selected}
                            queryParams={queryParams}
                            onSelectRow={onSelectRow}
                        />
                    </MuiTable>
                </TableContainer>
                <TablePagination
                    queryParams={queryParams}
                    totalCount={totalCount}
                    onUpdateQueryParams={onUpdateQueryParams}
                />
            </Paper>
        </Box>
    );
}
