import fileDownload from 'js-file-download';

import { IQueryParams, IPdfFile, IPagination } from '../types';
import { prepareQueryParams } from '../utils';
import authorizedRequest from './axios.config';

const BASE_URL = '/api/v1/pdf_files';

// Function for fetching PDF files from the API
export const getPdfFiles = async (queryParams: IQueryParams): Promise<IPagination<IPdfFile>> => {
    const urlSearchParams = prepareQueryParams(queryParams);
    const response = await authorizedRequest.get(`${BASE_URL}/paged?${urlSearchParams}`);
    return response.data;
};

// Function for uploading a file to the API
export const uploadPdfFile = async (file: File): Promise<IPdfFile> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await authorizedRequest.post(`${BASE_URL}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
};

// Function for downloading a PDF file from the API
export const downloadPdfFile = async (id: string): Promise<undefined> => {
    const response = await authorizedRequest.get(`${BASE_URL}/${id}/download`, {
        responseType: 'blob'
    });
    fileDownload(response.data, 'document.pdf');
};

// Function for indexing Webpages
export const indexPdfFile = async (id: string): Promise<IPdfFile> => {
    const response = await authorizedRequest.post(`${BASE_URL}/${id}/index`);
    return response.data;
};

export const excludePdfFileFromIndex = async (id: string): Promise<IPdfFile> => {
    const response = await authorizedRequest.post(`${BASE_URL}/${id}/exclude`);
    return response.data;
};
