import { jwtDecode } from 'jwt-decode';
import { SESSION_STORAGE_TOKEN_KEY } from '../consts';
import { ILoggedUser, ITokenPayload } from '../types';

// helper function checking if user session has expired
export const isUserAuthenticated = (user: ILoggedUser | null): boolean => {
    if (user === null) return false;

    const now = Math.round(Date.now() / 1000);
    return now < user.exp;
};

// helper function for checking if the user has admin claim or not
export const hasAdminClaim = (user: ILoggedUser | null): boolean => {
    if (user === null) return false;

    const adminClaim = user.claims.find((claim) => claim === 'admin' || claim === 'super_admin');
    return !!adminClaim;
};

// helper function for checking if the user has user claim or not
export const hasUserClaim = (user: ILoggedUser | null): boolean => {
    if (user === null) return false;

    const userClaim = user.claims.find((claim) => claim === 'user');
    return !!userClaim;
};

// helper function for extracting the user data from the token
export const getUserFromToken = (token: string | null): ILoggedUser => {
    if (!token) {
        throw Error('Token not found.');
    }

    const payload = jwtDecode<ITokenPayload>(token);

    if (!payload.sub || !payload.claims) {
        throw Error('Invalid token.');
    }

    const user = {
        email: payload.sub,
        claims: payload.claims,
        exp: payload.exp,
        access_token: token
    };

    return user;
};

// helper function for getting the auth token from session storage
export const getTokenFromSessionStorage = (): string | null => {
    return sessionStorage.getItem(SESSION_STORAGE_TOKEN_KEY);
};

// helper function for setting the auth token to session storage
export const setTokenToSessionStorage = (token: string): void => {
    sessionStorage.setItem(SESSION_STORAGE_TOKEN_KEY, token);
};

// helper function for deleting the auth token from session storage
export const deleteTokenFromSessionStorage = (): void => {
    sessionStorage.removeItem(SESSION_STORAGE_TOKEN_KEY);
};
