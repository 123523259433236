import { IHeadCell, ILoggedUser, ITableRow, IUser } from '../types';
import { resolveClaimsValue } from './table.utils';

// helper function for defining heading cells for Users table
export const prepareUsersTableHeadCells = (): IHeadCell[] => [
    {
        id: 'id',
        label: 'ID',
        sortDisabled: true
    },
    {
        id: 'email',
        label: 'Email',
        sortDisabled: true
    },
    {
        id: 'claims',
        label: 'Claims',
        sortDisabled: true
    }
];

// helper function for defining table rows for Users table
export const prepareUsersTableRows = (users: IUser[]): ITableRow[] =>
    users.map((user) => {
        const { id, email, claims } = user;
        return {
            id: id,
            data: [id, email, resolveClaimsValue(claims)]
        };
    });

// helper function for getting users inital letter, user for avatars
export const getUserInitalLetter = (user: ILoggedUser | null): string => {
    if (user === null) return 'U';

    return user.email.charAt(0).toUpperCase();
};
