import { ChangeEvent, useContext, useState } from 'react';
import { Backdrop, Button, CircularProgress, styled } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { AxiosError } from 'axios';

import { IPdfFile } from '../../../types';
import { uploadPdfFile } from '../../../services';
import { getResponseErrorMessage } from '../../../utils';
import { ToastAlertContext } from '../../../context/ToastAlertContext';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

interface Props {
    files: IPdfFile[];
    setFiles: (files: IPdfFile[]) => void;
}

// Component that handles the file uploading
export function FileUpload({ files, setFiles }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showAlert } = useContext(ToastAlertContext);

    const onFileUpload = async (file: File) => {
        try {
            setIsLoading(true);
            const newFile = await uploadPdfFile(file);
            setFiles([newFile, ...files]);
            showAlert('File uploaded successfully.', 'success');
        } catch (e: unknown) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files !== null) {
            onFileUpload(files[0]);
        }
    };

    return (
        <>
            <Button component="label" variant="contained" tabIndex={-1} startIcon={<UploadIcon />}>
                Upload a file
                <VisuallyHiddenInput accept="application/pdf" onChange={onFileChange} type="file" />
            </Button>
            {isLoading && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    );
}
