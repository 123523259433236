import { TableBody as MuiTableBody } from '@mui/material';

import { IQueryParams, ITableRow } from '../../types';
import { TableRow } from './TableRow';
import { Actions } from '../../consts';

interface Props {
    actions?: Actions[][];
    rows: ITableRow[];
    queryParams: IQueryParams;
    selected: number[];
    onSelectRow: (index: number) => void;
}

// Component that is rendering the table body and mapping through the rows
export function TableBody({ actions, rows, selected, onSelectRow }: Props) {
    return (
        <MuiTableBody>
            {rows.map((row, index) => (
                <TableRow
                    actions={actions && actions[index]}
                    key={index}
                    row={row}
                    index={index}
                    selected={selected}
                    onSelectRow={onSelectRow}
                />
            ))}
        </MuiTableBody>
    );
}
