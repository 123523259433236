import { Box, Typography } from '@mui/material';

// Component that renders the page header
export function PageHeader() {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 7 }}>
            <Typography variant="h5">Users</Typography>
        </Box>
    );
}
