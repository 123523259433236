import { PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import { hasUserClaim, hasAdminClaim, isUserAuthenticated } from '../../utils';

// Component that should be user as a wrapper for protected USER routes
export const UserRoute = ({ children }: PropsWithChildren) => {
    const { user } = useContext(AuthContext);
    const isAuthenticated = isUserAuthenticated(user);
    const adminClaim = hasAdminClaim(user);
    const userClaim = hasUserClaim(user);

    // If user is logged in show the private page, if not redirect him to login page
    return isAuthenticated && (userClaim || adminClaim) ? children : <Navigate to="/login" />;
};
