import { useState } from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

interface Props {
    messageId: string;
    onReviewMessage: (id: string, review: boolean) => void;
}

// Component that is rendering the ratings icons for chatbot answers
export function MessageRating({ messageId, onReviewMessage }: Props) {
    const [rating, setRating] = useState<undefined | boolean>();

    const onThumbUpClick = () => {
        setRating(true);
        onReviewMessage(messageId, true);
    };

    const onThumbDownClick = () => {
        setRating(false);
        onReviewMessage(messageId, false);
    };

    return (
        <Box
            sx={{
                ml: 2,
                display: 'flex',
                alignItems: 'center'
            }}>
            <Tooltip title="Review this answer positively.">
                <IconButton
                    onClick={onThumbUpClick}
                    color={rating ? 'primary' : 'default'}
                    size="large">
                    <ThumbUpIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Review this answer negatively.">
                <IconButton
                    onClick={onThumbDownClick}
                    color={rating === false ? 'error' : 'default'}
                    size="large">
                    <ThumbDownIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
}
