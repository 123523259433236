import { Box, CircularProgress } from '@mui/material';

// Component that renders the spinning icon when table is loading
export function TableLoader() {
    return (
        <Box
            sx={{
                mt: 21,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
            <CircularProgress />
        </Box>
    );
}
