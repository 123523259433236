import { useContext, useState } from 'react';

import { Box, Avatar, Menu, MenuItem, ListItemIcon, Tooltip, IconButton } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import Group from '@mui/icons-material/Group';
import Person from '@mui/icons-material/Person';

import { AuthContext } from '../../context/AuthContext';
import { deleteTokenFromSessionStorage, hasAdminClaim } from '../../utils';
import { useNavigate } from 'react-router-dom';

// Component that renders the user menu in the navbar
export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { user, setUser } = useContext(AuthContext);
    const isAdmin = hasAdminClaim(user);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUsersClick = () => {
        navigate('/admin/users');
        handleClose();
    };

    const onLogoutClick = () => {
        navigate('/login');
        deleteTokenFromSessionStorage();
        setUser(null);
        handleClose();
    };

    const isMenuOpen = Boolean(anchorEl);

    return (
        <>
            {/* User menu button */}
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={isMenuOpen ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isMenuOpen ? 'true' : undefined}>
                        <Avatar
                            sx={{
                                bgcolor: 'white',
                                width: 32,
                                height: 32
                            }}>
                            <Person color="primary" />
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>

            {/* User menu dropdown */}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isMenuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                {/* If user is admin render the users link */}
                {isAdmin && (
                    <MenuItem onClick={onUsersClick}>
                        <ListItemIcon>
                            <Group fontSize="small" />
                        </ListItemIcon>
                        Users
                    </MenuItem>
                )}
                <MenuItem onClick={onLogoutClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}
