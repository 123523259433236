import { Box, Typography } from '@mui/material';

import { IPdfFile } from '../../../types';
import { FileUpload } from './FileUpload';

interface Props {
    files: IPdfFile[];
    setFiles: (files: IPdfFile[]) => void;
}

// Component that renders the page header with a page title and upload button
export function PageHeader({ files, setFiles }: Props) {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 6 }}>
            <Typography variant="h5">Uploaded PDF files</Typography>
            <FileUpload files={files} setFiles={setFiles} />
        </Box>
    );
}
