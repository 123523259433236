import { FormEvent, useContext, useState } from 'react';
import { Avatar, Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { AxiosError } from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

import { LayoutWithNavbar } from '../../layouts';
import {
    getResponseErrorMessage,
    getUserFromToken,
    hasAdminClaim,
    hasUserClaim,
    isUserAuthenticated,
    setTokenToSessionStorage
} from '../../utils';
import { AuthContext } from '../../context/AuthContext';
import { ToastAlertContext } from '../../context/ToastAlertContext';
import { authenticateUser } from '../../services';

// Login page that renders the login form for the user
export function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const { user, setUser } = useContext(AuthContext);
    const { showAlert } = useContext(ToastAlertContext);

    // When form is submited and user logged in, navigate him to the home page
    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            // Get the user token and set it to local storage
            const { access_token } = await authenticateUser(email, password);
            setTokenToSessionStorage(access_token);

            // Get the user data from the token and set it to context state
            const user = getUserFromToken(access_token);
            setUser(user);

            // Navigate user to the root page and show the success alert
            navigate('/');
            showAlert('You have logged in successfully.', 'success');
        } catch (e) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const isAuthenticated =
        isUserAuthenticated(user) && (hasUserClaim(user) || hasAdminClaim(user));

    // If the user is already authenticated redirect to home page
    if (isAuthenticated) return <Navigate to="/" />;

    return (
        <LayoutWithNavbar>
            <Container component="main" maxWidth="xs">
                <Paper
                    sx={{
                        marginTop: {
                            xs: 12, // theme.breakpoints.up('xs')
                            sm: 20 // theme.breakpoints.up('md')
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 4
                    }}>
                    <Avatar sx={{ mb: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={onSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            disabled={email.trim() === '' || password.trim() === '' || isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Sign in
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </LayoutWithNavbar>
    );
}
