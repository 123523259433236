import { Snackbar, Alert, AlertTitle, AlertColor } from '@mui/material';

interface Props {
    message: string;
    severity: AlertColor;
    onClose: () => void;
}

// Component for showing toast messages to the user
export function ToastAlert({ message, severity, onClose }: Props) {
    // Alert title matches severity prop value with first letter uppercased
    const alertTitle = severity.charAt(0).toUpperCase() + severity.slice(1);
    const isOpen = message !== undefined && severity !== undefined;

    return (
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={onClose}>
            <Alert
                onClose={onClose}
                severity={severity}
                variant="filled"
                sx={{
                    width: {
                        sm: '100%',
                        md: 400
                    }
                }}>
                <AlertTitle>{alertTitle}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
}
