import ExcludeIcon from '@mui/icons-material/RemoveCircleRounded';
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { excludePdfFileFromIndex } from '../../../services';
import { useContext, useState } from 'react';
import { ToastAlertContext } from '../../../context/ToastAlertContext';
import { getResponseErrorMessage } from '../../../utils';
import { AxiosError } from 'axios';

interface Props {
    id: string;
}

// Component that handles the process action in the table
export function ExcludePdfAction({ id }: Props) {
    const [isExcludeDisabled, setIsExcludeDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showAlert } = useContext(ToastAlertContext);
    const onProcess = async () => {
        try {
            setIsLoading(true);
            await excludePdfFileFromIndex(id);
            showAlert('Pdf file excluded from index.', 'success');
            setIsExcludeDisabled(true);
        } catch (e) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
            setIsExcludeDisabled(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Tooltip title="Exclude from indexing">
                <div>
                    <IconButton
                        disabled={isExcludeDisabled}
                        color="warning"
                        onClick={onProcess}
                        aria-label="Exclude from indexing">
                        <ExcludeIcon />
                    </IconButton>
                </div>
            </Tooltip>
            {isLoading && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    );
}
