import { Box } from '@mui/material';

import { IQueryParams } from '../../../types';
import { SearchInput } from '../../../shared-components';

interface Props {
    onUpdateQueryParams: (updatedData: Partial<IQueryParams>) => void;
}

// Component that renders the page filters - search
export function UsersFilters({ onUpdateQueryParams }: Props) {
    const onSearchChange = (email: string) => {
        onUpdateQueryParams({ email: email.trim() });
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                mb: 2
            }}>
            <SearchInput label="Search by user email" onSearchChange={onSearchChange} />
        </Box>
    );
}
