import { TablePagination as TablePaginationMui } from '@mui/material';

import { IQueryParams } from '../../types';

interface Props {
    totalCount: number;
    queryParams: IQueryParams;
    onUpdateQueryParams: (updatedData: Partial<IQueryParams>) => void;
}

// Component that is rendering the pagination on the bottom of the table
export function TablePagination({ totalCount, queryParams, onUpdateQueryParams }: Props) {
    const handleChangePage = (page: number) => {
        const skip = page * queryParams.take;
        onUpdateQueryParams({ skip });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onUpdateQueryParams({ take: parseInt(event.target.value, 10), skip: 0 });
    };

    const page = Math.floor(queryParams.skip / queryParams.take);

    return (
        <TablePaginationMui
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            count={totalCount}
            rowsPerPage={queryParams.take}
            page={page}
            onPageChange={(e, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}
