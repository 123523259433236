import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    label: string;
    onSearchChange: (name: string) => void;
}

// Component for rendering the search input field
export function SearchInput({ label, onSearchChange }: Props) {
    const [value, setValue] = useState<string>('');

    const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSearchChange(value);
    };

    return (
        <form onSubmit={onSubmit}>
            <TextField
                id="search"
                type="search"
                size="small"
                color="primary"
                label={label}
                value={value}
                onChange={onChangeValue}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                sx={{
                    width: '100%',
                    minWidth: 180
                }}
            />
        </form>
    );
}
