import { Box } from '@mui/material';

import { IQueryParams } from '../../../types';
import { Status } from '../../../consts';
import { SearchInput, StatusSelect } from '../../../shared-components';

interface Props {
    onUpdateQueryParams: (updatedData: Partial<IQueryParams>) => void;
}

// Component that renders the page filters - search & filter by status
export function WebpagesFilters({ onUpdateQueryParams }: Props) {
    const onSearchChange = (name: string) => {
        onUpdateQueryParams({ name: name.trim() });
    };

    const onStatusChange = (status: string) => {
        onUpdateQueryParams({ status: status });
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                mb: 2
            }}>
            <SearchInput label="Search by web page name" onSearchChange={onSearchChange} />
            <StatusSelect
                statuses={[Status.Created, Status.Indexed]}
                onStatusChange={onStatusChange}
            />
        </Box>
    );
}
