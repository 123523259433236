import { Box, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { IChatMessage } from '../../../types';
import { generateRandomId } from '../../../utils';

interface Props {
    messageValue: string;
    onInputChange: (value: string) => void;
    onSendMessage: (chatMessage: IChatMessage) => void;
}

// Component that is rendering the input for composing new messages
export function MessageInput({ messageValue, onInputChange, onSendMessage }: Props) {
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (messageValue.trim() === '') return;

        const chatMessage: IChatMessage = {
            content: messageValue,
            name: 'User',
            id: generateRandomId()
        };

        onSendMessage(chatMessage);
    };

    return (
        <Box sx={{ width: '100%', px: 3, pt: 3 }}>
            <form onSubmit={onSubmit}>
                <TextField
                    fullWidth
                    placeholder="Enter your message"
                    variant="outlined"
                    value={messageValue}
                    onChange={(e) => onInputChange(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <IconButton type="submit" color="primary">
                                <SendIcon />
                            </IconButton>
                        )
                    }}
                />
            </form>
        </Box>
    );
}
