import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';

import { LayoutWithSidebar } from '../../layouts';
import { IChatMessage } from '../../types';
import { MessageList, MessageInput } from './components';
import { generateRandomId, getMessageIndexById, getResponseErrorMessage } from '../../utils';
import { rateMessage, sendMessage } from '../../services';
import { ToastAlertContext } from '../../context/ToastAlertContext';

// ChatBot page that renders the message list and the input for entering new messages
export function Chatbot() {
    const [messageInputValue, setMessageInputValue] = useState<string>('');
    const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<IChatMessage[]>([]);
    const [reviewedMessages, setReviewedMessages] = useState<string[]>([]);

    const { showAlert } = useContext(ToastAlertContext);

    const onReviewMessage = async (id: string, review: boolean) => {
        try {
            const answerMessageIndex = getMessageIndexById(messages, id);
            const question = messages[answerMessageIndex - 1]?.content;
            const answer = messages[answerMessageIndex]?.content;
            await rateMessage(id, question, answer, review);
            setReviewedMessages([...reviewedMessages, id]);
            showAlert('You have reviewed the answer successfully​.', 'success');
        } catch (e: unknown) {
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
        }
    };

    // Function for handling sending of the message to the API
    const onSendMessage = async (chatMessage: IChatMessage) => {
        // Add the user message and empty chatbot message
        const emptyBotMessage: IChatMessage = {
            content: '',
            name: 'FV-Bot',
            id: generateRandomId()
        };
        const newMessages = [...messages, chatMessage, emptyBotMessage];

        setMessages(newMessages);
        setMessageInputValue('');

        try {
            setIsMessageLoading(true);
            const botMessage = await sendMessage(messageInputValue);
            // Populate the last message content with the chatbot answer
            newMessages[newMessages.length - 1].content = botMessage.content;
            setMessages(newMessages);
        } catch (e: unknown) {
            // Remove the chatbot message if there is an error
            newMessages.pop();
            setMessages(newMessages);
            const errorMessage = getResponseErrorMessage(e as AxiosError);
            showAlert(errorMessage, 'error');
        } finally {
            setIsMessageLoading(false);
        }
    };

    return (
        <LayoutWithSidebar>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90vh'
                }}>
                <MessageList
                    isMessageLoading={isMessageLoading}
                    messages={messages}
                    reviewedMessages={reviewedMessages}
                    onReviewMessage={onReviewMessage}
                />
                <MessageInput
                    messageValue={messageInputValue}
                    onInputChange={setMessageInputValue}
                    onSendMessage={onSendMessage}
                />
            </Box>
        </LayoutWithSidebar>
    );
}
