import { IQueryParams } from '../types';

export const SIDEBAR_WIDTH = 240;

export const BOT_NAME = 'FV-Bot';

export const SESSION_STORAGE_TOKEN_KEY = 'access_token';

export enum Status {
    Uploaded = 'uploaded',
    Created = 'created',
    Indexed = 'indexed'
}

export const ASC_ORDER = 'asc';
export const DESC_ORDER = 'desc';

export const DEFAULT_QUERY_PARAMS: IQueryParams = {
    order: ASC_ORDER,
    orderBy: 'created_at',
    skip: 0,
    take: 100
};

export enum Actions {
    DOWNLOAD,
    INDEX_PDF,
    INDEX_WEBPAGE,
    EXCLUDE_PDF,
    EXCLUDE_WEBPAGE
}
